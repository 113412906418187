<template>
  <div class="rounded-2xl bg-lightestGreen pt-4 pb-5 px-6 text-[16px]">
    <div class="flex flex-row mb-3">
      <div class="font-semibold">
        {{ comment.userName }}
      </div>
      <div
        class="h-[2.5px] w-[2.5px] rounded-full bg-lightBlack self-center mx-2"
      ></div>
      <div class="text-lightBlack">
        {{
          $t('score.review-timeStamp', {
            date: date,
            time: time,
          })
        }}
      </div>
      <Pill
        v-if="roleName"
        class="
          text-[14px] text-darkGray
          bg-transparent
          border border-darkGray
          px-3
          mx-2
        "
      >
        {{ roleName }}
      </Pill>
    </div>
    <div class="text-lightBlack">
      {{ comment.comment }}
    </div>
  </div>
</template>

<script>
import { user as userHelper, general as generalHelper } from '@/helpers'
import Pill from '@/components/Pill'

export default {
  components: {
    Pill,
  },
  props: {
    comment: {
      type: Object,
      required: true,
      validator: ({ comment, userName, timestamp }) => {
        return !!comment && !!userName && !!timestamp
      },
    },
  },
  computed: {
    date() {
      return generalHelper.formatDate(this.comment.timestamp)
    },
    time() {
      return generalHelper.formatTime(this.comment.timestamp)
    },
    roleName() {
      return this.comment.role
        ? userHelper.roleName(this.comment.role)
        : undefined
    },
  },
}
</script>
