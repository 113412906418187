<template>
  <div class="w-full bg-white rounded-t-md px-[40px] py-5">
    <Button
      v-if="!addCommentMode"
      class="px-4 my-2"
      variant="secondary"
      icon="plus"
      @click="addButtonClicked = true"
    >
      {{ $t('score.review-addComment') }}
    </Button>

    <section v-else class="flex flex-col items-end mt-4" role="region">
      <Textarea
        class="w-full"
        v-model.trim="newComment"
        :placeholder="$t('score.review-addComment')"
        :disabled="disabled"
      />
      <Button
        class="px-4 ml-4"
        variant="primary"
        :disabled="disabled || newComment.length === 0"
        @click="postComment()"
      >
        {{ $t('general.done') }}
      </Button>
    </section>

    <div class="text-gray my-4" v-if="hasComments">
      {{ $t('score.review-allComments') }}
    </div>
    <Comment
      v-for="comment in reversedComments"
      :comment="comment"
      :key="comment.id"
      class="mb-7"
    />
  </div>
</template>

<script>
import { Comment } from '@/components/questionnaire'
import { Button, Textarea } from '@/components/form'

export default {
  components: {
    Comment,
    Button,
    Textarea,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['postComment'],
  data() {
    return {
      addButtonClicked: false,
      newComment: '',
    }
  },
  computed: {
    addCommentMode() {
      return this.addButtonClicked || !this.hasComments
    },
    reversedComments() {
      return this.comments.slice().reverse()
    },
    hasComments() {
      return this.comments.length > 0
    },
  },
  methods: {
    postComment() {
      if (this.newComment.length > 0) {
        this.$emit('postComment', this.newComment)
      }
    },
  },
  watch: {
    comments: {
      handler() {
        this.addButtonClicked = false
        this.newComment = ''
      },
      deep: true,
    },
  },
}
</script>
