<template>
  <Card :level="level" class="mt-4">
    <div class="px-10 py-6">
      <div class="flex justify-between">
        <span class="font-bold text-lg">
          {{ name }}
        </span>
        <Icon
          class="drag-handle text-darkGray w-8 cursor-[grab]"
          :class="disabled && 'invisible pointer-events-none'"
          name="drag-handle"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import { EQuestionnaireLevel } from '@/enums'
import { Card } from '@/components/questionnaire/cards'
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
    Card,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
      validator: val => EQuestionnaireLevel[val] !== undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EQuestionnaireLevel,
    }
  },
}
</script>
