<template>
  <Card :pending="pending" :level="level">
    <div>
      <div class="px-[40px] py-5">
        <h1 class="font-semibold">
          {{ displayedTitle }}
        </h1>
        <slot name="content" />
      </div>
      <div
        class="
          flex
          justify-end
          items-center
          bg-ultralightGray
          text-darkGray
          py-5
          px-[40px]
        "
      >
        <slot name="footer" />
      </div>
    </div>
  </Card>
</template>

<script>
import { EQuestionnaireLevel } from '@/enums'
import { Card } from '@/components/questionnaire/cards'

export default {
  components: {
    Card,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    level: {
      type: Number,
      required: true,
      validator: val => EQuestionnaireLevel[val] !== undefined,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EQuestionnaireLevel,
    }
  },
  computed: {
    displayedTitle() {
      return this.title || this.placeholder
    },
  },
}
</script>
