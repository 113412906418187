<template>
  <div
    class="bg-white border-t-4 rounded-t-md"
    :class="[{ 'cursor-wait': pending }, borderClasses]"
  >
    <slot />
  </div>
</template>

<script>
import { EQuestionnaireLevel } from '@/enums'

export default {
  props: {
    level: {
      type: Number,
      required: true,
      validator: val => EQuestionnaireLevel[val] !== undefined,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EQuestionnaireLevel,
    }
  },
  computed: {
    borderClasses() {
      return this.level === EQuestionnaireLevel.Question
        ? ' border-lightGreen'
        : this.level === EQuestionnaireLevel.Section
        ? 'border-yellow'
        : this.level === EQuestionnaireLevel.Category
        ? 'border-cyan'
        : 'border-gray'
    },
  },
}
</script>
