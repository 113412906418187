<template>
  <div
    :class="
      !highlighted
        ? 'border-lightGray bg-white'
        : questionnaire.state === EQuestionnaireState.Active
        ? 'border-cyan bg-lighterCyan'
        : questionnaire.state === EQuestionnaireState.Draft
        ? 'border-lightGreen bg-lightestGreen'
        : 'border-lightGray bg-lightestGray'
    "
    class="
      relative
      flex
      border-2
      rounded-lg
      text-darkGray
      w-[250px]
      min-h-[225px]
      hover:cursor-pointer
    "
    @mouseenter="highlighted = true"
    @mouseleave="highlighted = false"
    @click="goToQuestionnaire"
  >
    <img
      v-show="!highlighted"
      class="absolute inset-0 select-none"
      :src="
        questionnaire.state === EQuestionnaireState.Active
          ? BlueCircle
          : questionnaire.state === EQuestionnaireState.Draft
          ? GreenCircle
          : GrayCircle
      "
    />

    <div class="relative flex flex-col pt-4 pb-8 px-4 text-lightBlack">
      <div class="flex justify-between">
        <Pill
          :class="
            questionnaire.state === EQuestionnaireState.Active
              ? 'bg-cyan '
              : questionnaire.state === EQuestionnaireState.Draft
              ? 'bg-lightGreen'
              : 'bg-lightGray'
          "
          class="text-[13px] text-white font-medium px-3"
        >
          {{
            $t(
              `score.questionnaires-${
                questionnaire.state === EQuestionnaireState.Active
                  ? 'active'
                  : questionnaire.state === EQuestionnaireState.Draft
                  ? 'draft'
                  : 'inactive'
              }`
            )
          }}
        </Pill>
        <div v-show="questionnaire.live" class="flex">
          <div class="self-center font-bold text-sm uppercase">
            {{ $t('score.questionnaires-live') }}
          </div>
          <Icon name="play" class="w-5 h-5 ml-[1px]" />
        </div>
      </div>
      <div class="flex-grow flex flex-col justify-between p-4">
        <div class="font-semibold py-2 text-md">
          {{formatTitle(questionnaire.title)}}
        </div>
        <div class="py-2 text-xs ">
          {{
            $t('score.questionnaires-lastChanged', {
              date: formatDate(questionnaire.lastUpdate),
              time: formatTime(questionnaire.lastUpdate),
            })
          }}
        </div>
      </div>
      <div
        v-show="highlighted"
        class="absolute bottom-4 right-4 flex justify-end"
      >
        <Icon
          v-show="questionnaire.state === EQuestionnaireState.Draft"
          class="hover:text-black w-8 h-8 p-1"
          name="trash"
          @click.stop="$emit('delete', questionnaire)"
        />
        <Icon
          v-show="questionnaire.state === EQuestionnaireState.Active"
          class="hover:text-black w-8 h-8 p-1 ml-2"
          name="copy"
          @click.stop="$emit('copy', questionnaire)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { general } from '@/helpers'
import { EQuestionnaireState } from '@/enums'
import Icon from '@/components/Icon'
import Pill from '@/components/Pill'
import BlueCircle from '@images/circles-blue.png'
import GreenCircle from '@images/circles-green.png'
import GrayCircle from '@images/circles-gray.png'

export default {
  components: {
    Icon,
    Pill,
  },
  emits: ['delete', 'copy'],
  props: {
    questionnaire: {
      type: Object,
      required: true,
      validator: questionnaire =>
        questionnaire.id !== undefined &&
        questionnaire.state !== undefined &&
        questionnaire.lastUpdate !== undefined,
    },
  },
  data() {
    return {
      EQuestionnaireState,
      highlighted: false,
      GreenCircle,
      BlueCircle,
      GrayCircle,
    }
  },
  methods: {
    formatDate(dateString) {
      return general.formatDate(dateString)
    },
    formatTime(dateString) {
      return general.formatTime(dateString)
    },
    goToQuestionnaire() {
      this.$router.push({
        name: 'editCluster',
        params: { questionnaireId: this.questionnaire.id, clusterNum: '0' },
      })
    },
    formatTitle(title){
      let max;
      if(title.includes(' ')) max = 28;
      else max = 18;
      return title.length>max ?title.substring(0,max)+'...':title
    }
  },
}
</script>

<style></style>
