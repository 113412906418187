<template>
  <CardWithFooter v-bind="{ title, placeholder, level, pending }">
    <template #content>
      <slot name="content" />
    </template>
    <template #footer>
      <ButtonWithPopup
        type="button"
        variant="noBorder"
        icon="language"
        :items="languageOptions"
        @select="onLanguageSelect"
      >
        <template v-slot:items="{ item }">
          <div class="flex justify-between px-2">
            <HighlightText highlightClass="font-semibold">
              <span :class="{ 'text-lightGreen': item.selected }">
                {{ item.text }}
              </span>
            </HighlightText>
            <Checkbox
              class="pl-4"
              v-model="item.selected"
              :disabled="item.key === 'de'"
            >
            </Checkbox>
          </div>
        </template>
      </ButtonWithPopup>
      <div v-if="isTemplateEditable" class="flex">
        <Icon
          class="inline-block stroke-current cursor-pointer w-6"
          name="trash"
          @click="deleteItem"
        />
        <Icon
          v-if="level === EQuestionnaireLevel.Question"
          class="inline-block stroke-current cursor-pointer w-5 ml-7"
          name="copy"
          @click="copyItem"
        />
        <div class="flex border-r-[3px] border-gray mx-12 h-[35px]" />
        <Button
          class="px-12"
          type="submit"
          variant="primary"
          :disabled="disabled || pending"
          @click="confirm"
        >
          {{ $t('general.save') }}
        </Button>
      </div>
    </template>
  </CardWithFooter>
</template>

<script>
import { mapGetters } from 'vuex'
import { EQuestionnaireLevel } from '@/enums'
import { Button, ButtonWithPopup, Checkbox } from '@/components/form'
import { CardWithFooter } from '@/components/questionnaire/cards'
import Icon from '@/components/Icon'
import HighlightText from '@/components/HighlightText'

export default {
  components: {
    CardWithFooter,
    Button,
    ButtonWithPopup,
    Checkbox,
    Icon,
    HighlightText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    level: {
      type: Number,
      required: true,
      validator: val => EQuestionnaireLevel[val] !== undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change:language', 'delete', 'confirm', 'copy'],
  data() {
    return {
      EQuestionnaireLevel,
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['isTemplateEditable']),
    languageOptions() {
      return [
        {
          key: 'de',
          text: this.$t('general.language-de'),
          selected: true,
          disabled: true,
        },
        { key: 'en', text: this.$t('general.language-en') },
      ]
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },

    deleteItem() {
      this.$emit('delete')
    },

    copyItem() {
      this.$emit('copy')
    },

    onLanguageSelect(item) {
      const entry = this.languageOptions.find(entry => entry.key === item.key)
      entry.selected = !entry.selected
      this.$emit(
        'change:language',
        this.languageOptions
          .filter(option => option.selected)
          .map(({ key }) => key)
      )
    },
  },
}
</script>
