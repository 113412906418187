<template>
  <ButtonWithPopup
    :class="!$attrs.disabled && 'group'"
    variant="noBorder"
    icon="export"
    iconClasses="group-hover:text-primary"
    :disabled="$attrs.disabled"
    :items="exportOptions"
    @select="onExportSelected"
  >
    <template #text>
      <span class="group-hover:text-primary">
        {{ $t('general.export') }}
      </span>
    </template>
    <template #items="{ item }">
      <HighlightText class="px-2" highlightClass="font-semibold">
        {{ item.text }}
      </HighlightText>
    </template>
  </ButtonWithPopup>
</template>

<script>
import { EExportFormat } from '@/enums'
import HighlightText from '@/components/HighlightText'
import { ButtonWithPopup } from '@/components/form'

export default {
  components: {
    HighlightText,
    ButtonWithPopup,
  },
  data() {
    return {
      exportOptions: [
        {
          key: EExportFormat.JSON,
          text: 'JSON',
        },
        {
          key: EExportFormat.CSV,
          text: 'CSV',
        },
        {
          key: EExportFormat.Excel,
          text: 'Excel',
        },
      ],
    }
  },
  emits: ['onExportSelected'],
  methods: {
    onExportSelected({ key: exportFormat }) {
      this.$emit('onExportSelected', exportFormat)
    },
  },
}
</script>
