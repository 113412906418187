<template>
  <CardWithFooter v-bind="{ title, placeholder, level, pending }">
    <template #content>
      <slot name="content" />
    </template>
    <template #footer>
      <div class="text-[17px] font-medium">
        {{ $t('score.record-reachedScore', { score }) }}
      </div>
      <div class="flex-grow flex justify-end">
        <Button
          v-if="questionNav"
          class="px-6 mr-3"
          :disabled="disabledBack"
          variant="primary"
          icon="arrow-back"
          @click="back"
        >
          {{ $t('score.question-back') }}
        </Button>
        <Button
          v-if="questionNav"
          class="px-6"
          :disabled="disabledNext"
          variant="primary"
          icon="arrow-forward"
          @click="next"
        >
          {{ $t('score.question-next') }}
        </Button>
        <div v-if="questionNav" class="flex border-r-[2px] border-gray mx-8" />
        <Button
          class="px-12"
          type="submit"
          :variant="taxonomy ? 'taxonomy' : 'primary'"
          :disabled="disabled || pending"
          @click="confirm"
        >
          {{ $t('general.save') }}
        </Button>
      </div>
    </template>
  </CardWithFooter>
</template>

<script>
import { mapGetters } from 'vuex'
import { EQuestionnaireLevel } from '@/enums'
import { Button } from '@/components/form'
import { CardWithFooter } from '@/components/questionnaire/cards'
//import Icon from '@/components/Icon'

export default {
  components: {
    CardWithFooter,
    Button,
    //Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    level: {
      type: Number,
      required: true,
      validator: val => EQuestionnaireLevel[val] !== undefined,
    },
    score: {
      type: Number,
    },
    taxonomy: {
      type: Boolean,
      default: false,
    },
    questionNav: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledNext: {
      type: Boolean,
      default: false,
    },
    disabledBack: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete', 'confirm', 'copy', 'next', 'back'],
  data() {
    return {
      EQuestionnaireLevel,
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['isModified']),
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },

    deleteItem() {
      this.$emit('delete')
    },

    copyItem() {
      this.$emit('copy')
    },

    back() {
      this.$emit('back')
    },

    next() {
      this.$emit('next')
    },
  },
}
</script>
